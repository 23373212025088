var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("PageTitle", {
                    staticClass: "mb-4",
                    attrs: {
                      "page-title": !_vm.$language
                        ? "Grades of Batches"
                        : "ব্যাচের গ্রেড",
                    },
                  }),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-col",
                [
                  !_vm.$language
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            rounded: "",
                            color: "primary",
                            to: "certificateTemplate",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$language ? "সার্টিফিকেট" : "Certificate"
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("v-col", [
                _vm.isOwner
                  ? _c(
                      "div",
                      [
                        !_vm.$language
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  width: "190px",
                                  color: "primary",
                                  dark: "",
                                  rounded: "",
                                  depressed: "",
                                  to: "gradingFormat",
                                  loading: _vm.loadingGradingFormat,
                                },
                              },
                              [
                                _c("v-icon", [_vm._v("edit")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.gradingFormatPresent ? "" : "Add"
                                    ) +
                                    " Grading Format "
                                ),
                              ],
                              1
                            )
                          : _c(
                              "v-btn",
                              {
                                attrs: {
                                  width: "210px",
                                  color: "primary",
                                  dark: "",
                                  rounded: "",
                                  depressed: "",
                                  to: "gradingFormat",
                                  loading: _vm.loadingGradingFormat,
                                },
                              },
                              [
                                _c("v-icon", [_vm._v("edit")]),
                                _vm._v(
                                  " গ্রেডিং ফরম্যাট " +
                                    _vm._s(
                                      _vm.gradingFormatPresent ? "" : "যোগ করুন"
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        !_vm.$language
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  width: "190px",
                                  color: "#0089cb",
                                  dark: "",
                                  rounded: "",
                                  depressed: "",
                                  to: "gradingFormat",
                                },
                              },
                              [
                                _c("v-icon", [_vm._v("edit")]),
                                _vm._v(" Grading Format "),
                              ],
                              1
                            )
                          : _c(
                              "v-btn",
                              {
                                attrs: {
                                  width: "190px",
                                  color: "#0089cb",
                                  dark: "",
                                  rounded: "",
                                  depressed: "",
                                  to: "gradingFormat",
                                },
                              },
                              [
                                _c("v-icon", [_vm._v("edit")]),
                                _vm._v(" গ্রেডিং ফরম্যাট "),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
              ]),
            ],
            1
          ),
          _vm.renderNow
            ? _c(
                "div",
                [
                  !_vm.gradingFormatPresent
                    ? _c(
                        "v-card",
                        {
                          staticClass: "white black--text pa-4 ml-6",
                          attrs: { flat: "", "max-width": "1400px" },
                        },
                        [
                          _c("no-content-component", {
                            attrs: { message: _vm.noGradingFormatMessage },
                          }),
                        ],
                        1
                      )
                    : !_vm.noItemCondition
                    ? _c(
                        "v-card",
                        {
                          staticClass: "white black--text pa-4 ml-6 mt-4",
                          attrs: { flat: "", "max-width": "1400px" },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.gradingNotice))])]
                      )
                    : _vm._e(),
                  _c(
                    "v-card",
                    {
                      staticClass: "mt-4 ml-6 pa-4",
                      attrs: { flat: "", outlined: "", "max-width": "1400px" },
                    },
                    [
                      !_vm.noItemCondition
                        ? _c(
                            "div",
                            [
                              _vm.calculationFailed
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "font-size": "14px",
                                      },
                                    },
                                    [
                                      !_vm.$language
                                        ? _c("span", [
                                            _vm._v("Grade calculation failed."),
                                          ])
                                        : _c("span", [
                                            _vm._v("গ্রেড গণনা ব্যর্থ হয়েছে"),
                                          ]),
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.$language
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "ml-4 mt-4",
                                      staticStyle: { "font-size": "20px" },
                                    },
                                    [_vm._v(" List of Batches ")]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "ml-4 mt-4",
                                      staticStyle: { "font-size": "20px" },
                                    },
                                    [_vm._v(" ব্যাচ লিস্ট ")]
                                  ),
                              _c(
                                "v-list",
                                {
                                  staticClass: "ma-4",
                                  attrs: { outlined: "", rounded: "" },
                                },
                                _vm._l(_vm.batches, function (batch) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: batch.batch_id,
                                      staticStyle: {
                                        "border-bottom": "#c7c7c7",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "ma-0" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "ml-8" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "5" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          batch.batch_title
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            outlined: "",
                                                            rounded: "",
                                                            color: "primary",
                                                            width: "120px",
                                                            disabled:
                                                              !_vm.gradingFormatPresent,
                                                            loading:
                                                              _vm.calculating[
                                                                batch.batch_id
                                                              ],
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.calculateBatchResult(
                                                                batch.batch_id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.calculateText(
                                                                batch
                                                              )[_vm.$language]
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            outlined: "",
                                                            rounded: "",
                                                            color: "primary",
                                                            width: "120px",
                                                            disabled:
                                                              !_vm.allowViewCondition(
                                                                batch
                                                              ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.goToViewCertificate(
                                                                batch
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$language
                                                                ? "দেখুন"
                                                                : "View"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      !batch.result_published
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "white--text",
                                                              attrs: {
                                                                rounded: "",
                                                                depressed: "",
                                                                width: "120px",
                                                                color:
                                                                  "primary",
                                                                disabled:
                                                                  !_vm.allowPublishCondition(
                                                                    batch
                                                                  ),
                                                                loading:
                                                                  _vm
                                                                    .publishing[
                                                                    batch
                                                                      .batch_id
                                                                  ],
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.publishBatchResult(
                                                                      batch
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$language
                                                                      ? "প্রকাশ করুন"
                                                                      : "Publish"
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "primary--text inert",
                                                              attrs: {
                                                                rounded: "",
                                                                depressed: "",
                                                                width: "120px",
                                                                color: "white",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$language
                                                                      ? "প্রকাশিত"
                                                                      : "Published"
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _c("no-content-component", {
                            attrs: { message: _vm.noItemMessage },
                          }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-card",
                {
                  staticClass:
                    "white d-flex align-center justify-center ml-6 mt-4",
                  attrs: { flat: "", height: "200", "max-width": "1400" },
                },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" },
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }