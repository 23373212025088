<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6"
          ><PageTitle
            :page-title="!$language ? 'Grades of Batches' : 'ব্যাচের গ্রেড'"
            class="mb-4"
        /></v-col>
        <v-spacer />
        <v-col>
          <v-btn
            v-if="!$language"
            outlined
            rounded
            color="primary"
            to="certificateTemplate"
            >{{ $language ? "সার্টিফিকেট" : "Certificate" }}</v-btn
          >
        </v-col>
        <v-col>
          <div v-if="isOwner">
            <v-btn
              v-if="!$language"
              width="190px"
              color="primary"
              dark
              rounded
              depressed
              to="gradingFormat"
              :loading="loadingGradingFormat"
            >
              <v-icon>edit</v-icon>
              {{ gradingFormatPresent ? "" : "Add" }} Grading Format
            </v-btn>
            <v-btn
              v-else
              width="210px"
              color="primary"
              dark
              rounded
              depressed
              to="gradingFormat"
              :loading="loadingGradingFormat"
            >
              <v-icon>edit</v-icon>
              গ্রেডিং ফরম্যাট {{ gradingFormatPresent ? "" : "যোগ করুন" }}
            </v-btn>
          </div>
          <div v-else>
            <v-btn
              v-if="!$language"
              width="190px"
              color="#0089cb"
              dark
              rounded
              depressed
              to="gradingFormat"
            >
              <v-icon>edit</v-icon>
              Grading Format
            </v-btn>
            <v-btn
              v-else
              width="190px"
              color="#0089cb"
              dark
              rounded
              depressed
              to="gradingFormat"
            >
              <v-icon>edit</v-icon>
              গ্রেডিং ফরম্যাট
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div v-if="renderNow">
        <v-card
          v-if="!gradingFormatPresent"
          flat
          class="white black--text pa-4 ml-6"
          max-width="1400px"
        >
          <no-content-component
            :message="noGradingFormatMessage"
          ></no-content-component>
        </v-card>
        <v-card
          v-else-if="!noItemCondition"
          flat
          class="white black--text pa-4 ml-6 mt-4"
          max-width="1400px"
        >
          <span>{{ gradingNotice }}</span>
        </v-card>
        <v-card flat outlined class="mt-4 ml-6 pa-4" max-width="1400px">
          <div v-if="!noItemCondition">
            <span v-if="calculationFailed" style="color: red; font-size: 14px">
              <span v-if="!$language">Grade calculation failed.</span>
              <span v-else>গ্রেড গণনা ব্যর্থ হয়েছে</span>
            </span>
            <div v-if="!$language" style="font-size: 20px" class="ml-4 mt-4">
              List of Batches
            </div>
            <div v-else style="font-size: 20px" class="ml-4 mt-4">
              ব্যাচ লিস্ট
            </div>
            <v-list outlined rounded class="ma-4">
              <v-list-item
                v-for="batch in batches"
                :key="batch.batch_id"
                style="border-bottom: #c7c7c7"
              >
                <v-row class="ma-0">
                  <v-col cols="12" class="pa-0">
                    <v-row class="ml-8">
                      <v-col cols="5">{{ batch.batch_title }}</v-col>
                      <v-spacer />
                      <v-col>
                        <v-btn
                          outlined
                          rounded
                          color="primary"
                          width="120px"
                          :disabled="!gradingFormatPresent"
                          :loading="calculating[batch.batch_id]"
                          @click="calculateBatchResult(batch.batch_id)"
                          >{{ calculateText(batch)[$language] }}</v-btn
                        >
                      </v-col>
                      <v-col>
                        <v-btn
                          outlined
                          rounded
                          color="primary"
                          width="120px"
                          :disabled="!allowViewCondition(batch)"
                          @click="goToViewCertificate(batch)"
                          >{{ $language ? "দেখুন" : "View" }}</v-btn
                        >
                      </v-col>
                      <v-col>
                        <v-btn
                          v-if="!batch.result_published"
                          rounded
                          depressed
                          width="120px"
                          color="primary"
                          class="white--text"
                          :disabled="!allowPublishCondition(batch)"
                          :loading="publishing[batch.batch_id]"
                          @click="publishBatchResult(batch)"
                        >
                          {{ $language ? "প্রকাশ করুন" : "Publish" }}
                        </v-btn>
                        <v-btn
                          v-else
                          rounded
                          depressed
                          width="120px"
                          color="white"
                          class="primary--text inert"
                        >
                          {{ $language ? "প্রকাশিত" : "Published" }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </div>
          <no-content-component
            v-else
            :message="noItemMessage"
          ></no-content-component>
        </v-card>
      </div>
      <v-card
        v-else
        flat
        class="white d-flex align-center justify-center ml-6 mt-4"
        height="200"
        max-width="1400"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import gradingApi from "#ecf/grading/api";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "BatchGradeList",
  props: {
    courseId: {
      type: String,
      required: true,
    },
    partner_code: {
      type: String,
      required: true,
    },
  },
  components: { NoContentComponent },
  data() {
    return {
      calculating: {},
      publishing: {},
      loadingGradingFormat: true,
      calculationFailed: false,
      gradingFormat: undefined,
      renderNow: false,
    };
  },
  computed: {
    currentOrganization() {
      return this.$store.state.organization.currentOrganization;
    },
    isOwner() {
      return this.$store.state.user.user.access[
        this.currentOrganization.partner_code
      ].includes("owner");
    },
    batches() {
      return this.$store.getters["batch/batches"][this.courseId];
    },
    gradingFormatPresent() {
      return !(
        this.gradingFormat === undefined ||
        Object.keys(this.gradingFormat).length === 0
      );
    },
    noItemCondition() {
      return this.batches === undefined || this.batches?.length == 0;
    },
    allowViewCondition() {
      return (batch) => {
        return batch.result_calculated === true;
      };
    },
    allowPublishCondition() {
      return (batch) => {
        return batch.result_calculated && !batch.result_published;
      };
    },
    gradingNotice() {
      return !this.$language
        ? "You must first calculate results for a batch before you can View or Publish them"
        : "কোনো ব্যাচ এর পরীক্ষার ফলাফল দেখা অথবা প্রকাশ করার জন্য আপনাকে প্রথমে ফলাফল গণনা করতে হবে";
    },
    calculateText(){
      return batch => {
        return batch.result_published ? {
          true: "পূনঃগণনা",
          false: "Re-calculate"
        } : {
          true: "গণনা করুন",
          false: "Calculate"
        }
      }
    },
    noGradingFormatMessage() {
      return !this.$language
        ? "Add grading format to calculate student grades."
        : "শিক্ষার্থীদের গ্রেড গণনা করতে গ্রেডিং বিন্যাস যোগ করুন।";
    },
    noItemMessage() {
      return !this.$language ? "There are no batches" : "কোনো ব্যাচ নেই";
    },
  },
  async created() {
    const isOnline = window.navigator.onLine;
      if ( !isOnline ) {
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        this.renderNow = true;
        this.loadingGradingFormat = false;
        return;
      }
    await this.fetchBatches();
    await this.getGradingFormat(this.courseId);
    this.renderNow = true;
  },
  methods: {
    async fetchBatches() {
      try {
        await this.$store.dispatch("batch/list", {
          courseId: this.courseId,
          partner_code:
            this.$store.state.organization.currentOrganization.partner_code,
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async getGradingFormat(course_id) {
      
      try {
        this.loadingGradingFormat = true;
        let response = await gradingApi.get({ course_id: this.courseId });
        this.gradingFormat = response.data.content.grading;
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e.message),
        ]);
      } finally {
        this.loadingGradingFormat = false;
      }
    },
    async calculateBatchResult(batch_id) {
      if (this.calculating[batch_id]) return;
      try {
        this.$set(this.calculating, batch_id, true);
        let response = await gradingApi.calculate({
          course_id: this.courseId,
          batch_id: batch_id,
        });
        this.gradingFormat = response.data.content;
        if (response.status === 200) this.calculationFailed = false;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
        this.calculationFailed = true;
      } finally {
        this.$set(this.calculating, batch_id, false);
      }
      await this.fetchBatches();
    },
    async publishBatchResult(batch) {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ) {
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      const batch_id = batch.batch_id;
      if (this.publishing[batch_id]) return;
      try {
        this.$set(this.publishing, batch_id, true);
        let response = await gradingApi.publish({
          course_id: this.courseId,
          batch_id: batch_id,
        });
        this.gradingFormat = response.data.content;
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e.message),
        ]);
      } finally {
        this.$set(this.publishing, batch_id, false);
      }
      await this.fetchBatches();
    },
    async goToViewCertificate(batch) {
      this.$router.push({
        name: "viewGrades",
        params: { batchId: batch.batch_id },
      });
    },
  },
};
</script>

<style scoped></style>
